import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import EvaluationStacked from "../../../../../components/evaluation-stacked"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import Grid from "../../../../../components/grid"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import SolutionsStacked from "../../../../../components/solutions-stacked"
import Stack from "../../../../../components/stack"
import Divider from "../../../../../components/divider"

const Page = () => {
  const { getTask, answerStatusForArray, getStatsForTaskWithArray } =
    useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })
  const taskProps = {
    courseId: "versteckte-ordnung",
    chapterId: "02-ordnung",
    taskId: "ottos-mops",
  }
  const answers = answerStatusForArray(taskProps)
  const statistics = getStatsForTaskWithArray(taskProps)
  const solutions = getTask(taskProps).solution

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/versteckte-ordnung/02-ordnung/inventur"
        />
      }
    >
      <Seo title="Ottos Mops" />
      <Stack>
        <FeedbackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <Grid columns={[1, 2]}>
          <EvaluationStacked answers={answers} />
          <SolutionsStacked solutions={solutions} />
        </Grid>
        <Divider size={4} />
        <Grid columns={[1, 2]} reverse={[false, true]}>
          <Stack>
            <Paragraph>
              Ernst Jandl hat sich für dieses Gedicht etwas ganz besonderes
              ausgedacht: Er hat nur Wörter mit dem Vokal o verwendet.
            </Paragraph>
            <Paragraph>
              Vers für Vers zählt er dabei auf, was Ottos Mops tut. So wirkt das
              Gedicht wie eine Aufzählung.
            </Paragraph>
          </Stack>
          <PaperStack>
            <Poem size={[3, 3, 4]}>
              <p>ottos mops trotzt</p>
              <p>otto: fort mops fort</p>
              <p>ottos mops hopst fort</p>
              <p>otto: soso</p>
              <br />
              <p>otto holt koks</p>
              <p>otto holt obst</p>
              <p>otto horcht</p>
              <p>otto: mops mops</p>
              <p>otto hofft</p>
              <br />
              <p>ottos mops klopft</p>
              <p>otto: komm mops komm</p>
              <p>ottos mops kommt</p>
              <p>ottos mops kotzt</p>
              <p>otto: ogottogott</p>
            </Poem>
          </PaperStack>
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
